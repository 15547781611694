import React from "react";
import { useNavigate } from "react-router-dom";

const OldHomes = () => {
  let navigate = useNavigate();
  return (
    <>
      <h1>OldHomes</h1>
    </>
  );
};

export default OldHomes;
