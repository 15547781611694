import "./App.css";
import { ThemeProvider } from "react-bootstrap";
import { Routes, Route } from "react-router-dom";
import HomPage from "./pages/HomePage/HomePage";
import NewHomes from "./pages/NewHomes/NewHomes";
import OldHomes from "./pages/OldHomes/OldHomes";
import Remodeling from "./pages/Remodeling/Remodeling";
import BuildingHomes from "./pages/BuildingHomes/BuildingHomes";
import Navigation from "./components/Navigation";

function App() {
  return (
    <>
      <ThemeProvider
        breakpoints={["xxxl", "xxl", "xl", "lg", "md", "sm", "xs", "xxs"]}
        minBreakpoint="xxs"
      >
        <Navigation />
        <Routes>
          <Route path="/" element={<HomPage />} />
          <Route path="/OldHomes" element={<OldHomes />} />
          <Route path="/NewHomes" element={<NewHomes />} />
          <Route path="/Remodeling" element={<Remodeling />} />
          <Route path="/BuildingHomes" element={<BuildingHomes />} />
        </Routes>
      </ThemeProvider>
    </>
  );
}

export default App;
