import React from "react";
import Row from "react-bootstrap/esm/Row";
import ServiceContainer from "../../components/ServiceContainer";
import "./homePage.css";

const HomPage = () => {
  return (
    <>
      <Row style={{ borderBottom: "1px solid #99ddff" }} className="mt-5 pb-3">
        <h1 className="text-center  primary-blue">Old School Painting</h1>
      </Row>
      <ServiceContainer
        title="Painting Old Homes"
        imgSrc="place-holder.jpg"
        route="OldHomes"
      />
      <ServiceContainer
        title="Painting New Homes"
        imgSrc="place-holder.jpg"
        route="NewHomes"
      />
      <ServiceContainer
        title="Remodeling"
        imgSrc="place-holder.jpg"
        route="Remodeling"
      />
      <ServiceContainer
        title="Building Homes"
        imgSrc="place-holder.jpg"
        route="BuildingHomes"
      />
    </>
  );
};

export default HomPage;
