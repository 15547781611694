import React from "react";
import Row from "react-bootstrap/esm/Row";
import Col from "react-bootstrap/esm/Col";
import Image from "react-bootstrap/esm/Image";
import { Link } from "react-router-dom";

const ServiceContainer = ({ title, imgSrc, route }) => {
  return (
    <>
      <Link to={route} style={{ textDecoration: "none" }}>
        <Row className="mt-5">
          <Col md xl={6}>
            <div className="center">
              <Image
                className="img-thumb"
                src={require("../assets/images/" + imgSrc)}
                alt={title}
                rounded={true}
              />
            </div>
          </Col>
          <Col md xl={6}>
            <div className="center ">
              <h2 className="off-blue">{title}</h2>
            </div>
          </Col>
        </Row>
      </Link>
    </>
  );
};

export default ServiceContainer;
