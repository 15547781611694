import React from "react";

const BuildingHomes = () => {
  return (
    <>
      <h1>BuildingHomes</h1>
    </>
  );
};

export default BuildingHomes;
