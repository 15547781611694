import React from "react";
import Nav from "react-bootstrap/Nav";
import Image from "react-bootstrap/esm/Image";
import logo from "../assets/images/logo.svg";
import showNavIcon from "../assets/images/nav-icon.svg";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";

const Navigation = () => {
  const location = useLocation();
  const [activePage, setActivePage] = useState(location.pathname);
  const [showNav, setShowNav] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
    if (window.innerWidth > 686.98) {
      setShowNav(true);
    } else {
      setShowNav(false);
    }
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    window.addEventListener("resize", resizeWindow);

    resizeWindow();

    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  const handleChange = (event) => {
    event.target.active = true;
  };

  const toggleNav = (event) => {
    showNav ? setShowNav(false) : setShowNav(true);
  };
  return (
    <>
      <Nav variant="tabs">
        <Nav.Item>
          <Nav.Link
            id="nav-home"
            className="link nav-item"
            active={activePage == "/" ? true : false}
            href="/"
            onClick={handleChange}
          >
            <Image
              src={logo}
              style={{
                width: "20px",
                marginRight: "5px",
                justifyContent: "flex-start",
              }}
            ></Image>
            Home
          </Nav.Link>
          <div style={{ textAlign: "center" }}>
            <Image
              style={{
                width: "50px",
                height: "auto",
                backgroundColor: "#e3e3e3",
                cursor: "pointer",
                display: windowWidth >= 687 ? "none" : "inline-block",
              }}
              src={showNavIcon}
              onClick={toggleNav}
            ></Image>
          </div>
        </Nav.Item>

        <Nav.Item>
          <Nav.Link
            style={{ display: !showNav ? "none" : "flex" }}
            active={activePage === "/OldHomes" ? true : false}
            className="link nav-item"
            onClick={handleChange}
            href="/OldHomes"
          >
            Old Homes
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            active={activePage === "/NewHomes" ? true : false}
            style={{
              display: !showNav ? "none" : "flex",
            }}
            className="link nav-item "
            href="/NewHomes"
            onClick={handleChange}
          >
            New Homes
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            active={activePage === "/Remodeling" ? true : false}
            style={{ display: !showNav ? "none" : "flex" }}
            className="link nav-item"
            href="/Remodeling"
            onClick={handleChange}
          >
            Remodeling
          </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link
            active={activePage === "/BuildingHomes" ? true : false}
            style={{ display: !showNav ? "none" : "flex" }}
            className="link nav-item"
            href="/BuildingHomes"
            onClick={handleChange}
          >
            Building Homes
          </Nav.Link>
        </Nav.Item>
      </Nav>
    </>
  );
};

export default Navigation;
